var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('a-card',{staticClass:"header-solid h-full p-5 shadow-lg rounded-lg ",attrs:{"bordered":false}},[_c('div',[_c('a-form',{attrs:{"form":_vm.form,"layout":"vertical"}},[_c('a-row',{attrs:{"gutter":16}},[_c('a-col',{attrs:{"span":24,"md":12}},[_c('a-form-item',{attrs:{"label":"Company Name"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'name',

                {
                  initialValue: _vm.user.name,

                  rules: [
                    { required: true, message: 'Please enter Company name' } ],
                } ]),expression:"[\n                'name',\n\n                {\n                  initialValue: user.name,\n\n                  rules: [\n                    { required: true, message: 'Please enter Company name' },\n                  ],\n                },\n              ]"}],attrs:{"placeholder":"Please enter your Company name"}})],1)],1),_c('a-col',{attrs:{"span":24,"md":12}},[_c('a-form-item',{attrs:{"label":"Website(Optional)"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'website',
                {
                  initialValue: _vm.user.last_name,
                  rules: [
                    {
                      required: false,
                      message: 'website',
                    } ],
                } ]),expression:"[\n                'website',\n                {\n                  initialValue: user.last_name,\n                  rules: [\n                    {\n                      required: false,\n                      message: 'website',\n                    },\n                  ],\n                },\n              ]"}],attrs:{"placeholder":"","addon-before":"https://"}})],1)],1)],1),_c('a-row',{attrs:{"gutter":16}},[_c('a-col',{attrs:{"span":24,"md":12}},[_c('a-form-item',{attrs:{"label":"Official Email"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'email',
                {
                  initialValue: _vm.user.email,
                  rules: [
                    { required: true, message: 'Please select your email' } ],
                } ]),expression:"[\n                'email',\n                {\n                  initialValue: user.email,\n                  rules: [\n                    { required: true, message: 'Please select your email' },\n                  ],\n                },\n              ]"}],attrs:{"placeholder":"Please a valid email","type":"email","disabled":""}})],1)],1),_c('a-col',{attrs:{"span":12}},[_c('a-form-item',{attrs:{"label":"Phone Number"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'phone',
                {
                  initialValue: _vm.user.phone,
                  rules: [
                    {
                      required: true,
                      message: 'Please enter your phone number',
                    } ],
                } ]),expression:"[\n                'phone',\n                {\n                  initialValue: user.phone,\n                  rules: [\n                    {\n                      required: true,\n                      message: 'Please enter your phone number',\n                    },\n                  ],\n                },\n              ]"}],attrs:{"placeholder":"Please enter a valid phone number","type":"email"}})],1)],1)],1),_c('a-row',{attrs:{"gutter":16}},[_c('a-col',{attrs:{"span":24,"md":12}},[_c('a-form-item',{attrs:{"label":"People in your team"}},[_c('a-input-number',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'team',
                {
                  initialValue: _vm.user.team,
                  rules: [
                    {
                      required: true,
                      message: 'Please enter total number members of your team',
                    } ],
                } ]),expression:"[\n                'team',\n                {\n                  initialValue: user.team,\n                  rules: [\n                    {\n                      required: true,\n                      message: 'Please enter total number members of your team',\n                    },\n                  ],\n                },\n              ]"}],staticStyle:{"width":"100%"}})],1)],1),_c('a-col',{attrs:{"span":24,"md":12}},[_c('a-form-item',{attrs:{"label":"Year Founded"}},[_c('a-month-picker',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'year_founded',
                {
                  initialValue: _vm.user.year_founded,
                  rules: [
                    {
                      required: true,
                      message:
                        'Please enter Year the firm was founded',
                    } ],
                } ]),expression:"[\n                'year_founded',\n                {\n                  initialValue: user.year_founded,\n                  rules: [\n                    {\n                      required: true,\n                      message:\n                        'Please enter Year the firm was founded',\n                    },\n                  ],\n                },\n              ]"}],staticStyle:{"width":"100%"}})],1)],1)],1),_c('a-row',{attrs:{"gutter":16}},[_c('a-col',{attrs:{"span":24}},[_c('a-form-item',{attrs:{"label":"Description"}},[_c('a-textarea',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'description',
                {
                  initialValue: _vm.user.description,
                  rules: [
                    {
                      required: true,
                      message: 'Please describe your firm',
                    } ],
                } ]),expression:"[\n                'description',\n                {\n                  initialValue: user.description,\n                  rules: [\n                    {\n                      required: true,\n                      message: 'Please describe your firm',\n                    },\n                  ],\n                },\n              ]"}],attrs:{"rows":4,"placeholder":""}})],1)],1)],1),_c('a-row',{attrs:{"gutter":16}},[_c('a-col',{attrs:{"span":24,"md":12}},[_c('a-form-item',{attrs:{"label":"Company Logo "}},[_c('input',{staticClass:"block w-50 text-sm text-gray-900 border border-gray-300 rounded-lg cursor-pointer bg-gray-50 dark:text-gray-400 focus:outline-none dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400",attrs:{"id":"multiple_files","type":"file","accept":"image/*"},on:{"change":_vm.loadImage}})]),(_vm.uploadProgress>0)?_c('a-progress',{attrs:{"percent":_vm.uploadProgress}}):_vm._e()],1),_c('a-col',{attrs:{"span":24,"md":12}},[(_vm.user.logo)?_c('a-avatar',{attrs:{"src":_vm.user.logo,"size":160}}):_c('a-avatar',{attrs:{"icon":"user","size":160}})],1)],1),_c('a-checkbox',{staticClass:"mb-3",attrs:{"checked":_vm.terms},on:{"change":_vm.changeTerms}},[_vm._v(" I understand that the information submitted on this page will be visibile to all potential clients ")])],1),_c('div',[_c('a-modal',{attrs:{"title":"Resize Image to Fit","on-ok":"handleOk"},model:{value:(_vm.showModal),callback:function ($$v) {_vm.showModal=$$v},expression:"showModal"}},[_c('template',{slot:"footer"},[_c('a-button',{key:"back",on:{"click":_vm.handleCancel}},[_vm._v(" Close ")]),_c('a-button',{key:"submit",attrs:{"type":"primary","loading":_vm.loading},on:{"click":_vm.uploadToFirestore}},[_vm._v(" Upload ")])],1),_c('cropper',{ref:"cropper",staticClass:"cropper",attrs:{"src":_vm.image.src,"stencil-props":{
            handlers: {},
            movable: false,
            resizable: false,
            aspectRatio: 1,
          },"resize-image":{
            adjustStencil: false,
          },"image-restriction":"stencil","stencil-size":{
            width: 144,
            height: 144,
          }}})],2)],1),_c('div',[_c('a-button',{attrs:{"type":"primary","loading":_vm.loading,"disabled":_vm.user.status === 'pending approval' || !_vm.terms},on:{"click":_vm.handleSubmit}},[_vm._v("Save and Continue ")])],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }