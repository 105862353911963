<template>

	<!-- Authors Table Card -->
	<a-card :bordered="false" class="header-solid h-full">
    <template >
      <a-modal v-model="showModal" title="Enter multiple emails separated by commas" on-ok="handleOk">
          <template slot="footer">
            <a-button key="back" @click="handleCancel"> Close </a-button>
            <a-button
            
              key="submit"
              type="primary"
              :loading="loading"
              @click="handleSubmit"
            >
              {{ !uploading?'Submit':'Processsing...' }}
            </a-button>
            
          </template>
          <a-form :form="form" layout="vertical">
        <a-row :gutter="16">
      
          <a-col :span="24">
            <a-form-item label="Email">
              <a-input
                v-decorator="[
                  'email',
                  {
                  
                    rules: [
                      { required: true, message: '' },
                    ],
                  },
                ]"
                placeholder="Please enter a valid email"
                type="email"
              />
            </a-form-item>
          </a-col>
        </a-row>
     
     
      </a-form>
        </a-modal>
			<div class="flex items-center justify-end">
					<button @click="showDrawer" class="px-8 py-2 bg-red-500 text-white rounded-lg justify-self-end">Invite Team Members</button>
				
			</div>

		</template>
    <a-table :columns="columns" :data-source="members">
    <a slot="name" slot-scope="text">{{ text }}</a>
  </a-table>
	</a-card>
	<!-- / Authors Table Card -->
 


</template>

<script>
import { mapState } from "vuex";
import { Cropper, Preview } from "vue-advanced-cropper";
const columns = [
  {
    title: "Name",
    dataIndex: "name",
    key: "name",
    scopedSlots: { customRender: "name" },
  },
  {
    title: "Email",
    dataIndex: "email",
    key: "age",
  },
  {
    title: "Phone Number",
    dataIndex: "phone",
    key: "address 1",
    ellipsis: true,
  },
  {
    title: "Job title",
    dataIndex: "title",
    key: "address 2",
  },
  {
    title: "Admission number",
    dataIndex: "address",
    key: "address 3",
    ellipsis: true,
  },
  {
    title: "Date Joined",
    dataIndex: "address",
    key: "address 4",
    ellipsis: true,
  },
];
export default {
  props: ["user"],
  data() {
    return {
      columns: columns,
      visible: false,
      formLayout: "horizontal",
      form: this.$form.createForm(this, { name: "coordinated" }),
      image: {
        src: null,
        type: null,
      },
      fileList: [],
      uploading: false,
      isFormDirty: false,
      uploadProgress: 0,
      terms: false,
      members: [],
      resultImage: null,
      coordinates: {
        width: 200,
        height: 200,
      },
      img: "https://images.unsplash.com/photo-1600984575359-310ae7b6bdf2?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=700&q=80",
      result: {
        coordinates: null,
        image: null,
      },
      showModal: false,
    };
  },
  components: {
    Cropper,
    Preview,
    // other components...
  },
  methods: {
    showDrawer() {
      this.showModal = true;
    },
    onClose() {
      this.showModal = false;
    },
    handleCancel(){
      this.showModal=false
    },
    async handleSubmit(e) {
      this.uploading=true
      e.preventDefault();
      this.form.validateFields(async (err, values) => {
        if (!err) {
          this.$store.dispatch("sendMail", {
        name: 'Advocate',
        email: values.email,
        subject: "Welcome to Dial A Lawyer",
        content: `Your firm has requested you to create an account and join their profile`,
      }).then(()=>{
        this.showModal=false
        this.uploading=false
      }).catch((err)=>{
        console.log(err);
        this.uploading=false
      })

        }
      });
    },
  },
};
</script>