var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('a-card',{staticClass:"header-solid h-full p-5 shadow-lg rounded-lg ",attrs:{"bordered":false}},[_c('div',[_c('a-form',{attrs:{"form":_vm.form,"layout":"vertical"}},[_c('a-row',{attrs:{"gutter":16}},[_c('a-col',{attrs:{"span":24,"md":12}},[_c('a-form-item',{attrs:{"label":"Primary Office Location"}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'location',
                {
                  initialValue: _vm.user.location,
                  rules: [
                    { required: true, message: 'Please enter your location' } ],
                } ]),expression:"[\n                'location',\n                {\n                  initialValue: user.location,\n                  rules: [\n                    { required: true, message: 'Please enter your location' },\n                  ],\n                },\n              ]"}]},_vm._l((_vm.counties),function(county){return _c('a-select-option',{key:county,attrs:{"value":county}},[_vm._v(" "+_vm._s(county)+" ")])}),1)],1)],1),_c('a-col',{attrs:{"span":24,"md":12}},[_c('a-form-item',{attrs:{"label":"Languages"}},[_c('a-tooltip',{attrs:{"slot":"suffix","title":"Enter all Languages your firm can handle cases in"},slot:"suffix"},[_c('a-icon',{staticStyle:{"color":"rgba(0, 0, 0, 0.45)"},attrs:{"type":"info-circle"}})],1),_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'languages',
                {
                  initialValue: _vm.user.languages,
                  rules: [{ required: true, message: 'field is required' }],
                } ]),expression:"[\n                'languages',\n                {\n                  initialValue: user.languages,\n                  rules: [{ required: true, message: 'field is required' }],\n                },\n              ]"}],staticStyle:{"width":"100%"},attrs:{"mode":"multiple","placeholder":"Type or search"}},_vm._l((_vm.languages),function(i){return _c('a-select-option',{key:i},[_vm._v(" "+_vm._s(i)+" ")])}),1)],1)],1)],1),_c('a-row',{attrs:{"gutter":16}},[_c('a-col',{attrs:{"span":24,"md":12}},[_c('a-form-item',{attrs:{"label":"Address"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'address',
                {
                  initialValue: _vm.user.address,
                  rules: [
                    {
                      required: false,
                      message: 'Please enter your address',
                    } ],
                } ]),expression:"[\n                'address',\n                {\n                  initialValue: user.address,\n                  rules: [\n                    {\n                      required: false,\n                      message: 'Please enter your address',\n                    },\n                  ],\n                },\n              ]"}],staticStyle:{"width":"100%"},attrs:{"placeholder":"address"}})],1)],1),_c('a-col',{attrs:{"span":24,"md":12}},[_c('a-form-item',{attrs:{"label":"Free Consultation"}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'consultation',
                {
                  initialValue: _vm.user.consultation,
                  rules: [
                    { required: true, message: 'Field is required' } ],
                } ]),expression:"[\n                'consultation',\n                {\n                  initialValue: user.consultation,\n                  rules: [\n                    { required: true, message: 'Field is required' },\n                  ],\n                },\n              ]"}]},_vm._l((_vm.freeConsultation),function(county){return _c('a-select-option',{key:county,attrs:{"value":county}},[_vm._v(" "+_vm._s(county)+" ")])}),1)],1)],1)],1),_c('a-row',{attrs:{"gutter":16}},[_c('a-col',{attrs:{"span":24,"md":12}},[_c('a-form-item',{attrs:{"label":"Primary Practice Area"}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'specialisation',
                {
                  initialValue: _vm.user.specialisation,
                  rules: [{ required: true, message: 'field is required' }],
                } ]),expression:"[\n                'specialisation',\n                {\n                  initialValue: user.specialisation,\n                  rules: [{ required: true, message: 'field is required' }],\n                },\n              ]"}],staticStyle:{"width":"100%"},attrs:{"placeholder":"Type or search"}},_vm._l((_vm.practiseAreas),function(i){return _c('a-select-option',{key:i},[_vm._v(" "+_vm._s(i)+" ")])}),1)],1)],1),_c('a-col',{attrs:{"span":24,"md":12}},[_c('a-form-item',{attrs:{"label":"Other Practice Areas (Select upto 3 that apply)"}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'practise_areas',
                {
                  initialValue: _vm.user.practise_areas,
                  rules: [{ required: true, message: 'field is required' }],
                } ]),expression:"[\n                'practise_areas',\n                {\n                  initialValue: user.practise_areas,\n                  rules: [{ required: true, message: 'field is required' }],\n                },\n              ]"}],staticStyle:{"width":"100%"},attrs:{"mode":"tags","maxTagCount":3,"placeholder":"Type or search"}},_vm._l((_vm.practiseAreas),function(i){return _c('a-select-option',{key:i},[_vm._v(" "+_vm._s(i)+" ")])}),1)],1)],1)],1),_c('a-row',{attrs:{"gutter":16}},[_c('a-col',{attrs:{"span":24,"md":12}},[_c('a-form-item',{attrs:{"label":"Your LinkedIn link(Optional)"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'linkedIn',
                {
                  initialValue: _vm.user.linkedIn,
                  rules: [
                    {
                      required: false,
                      message: 'Filed is required',
                    } ],
                } ]),expression:"[\n                'linkedIn',\n                {\n                  initialValue: user.linkedIn,\n                  rules: [\n                    {\n                      required: false,\n                      message: 'Filed is required',\n                    },\n                  ],\n                },\n              ]"}],staticStyle:{"width":"100%"}})],1)],1),_c('a-col',{attrs:{"span":24,"md":12}},[_c('a-form-item',{attrs:{"label":"Your Twitter Link(Optional)"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'twitter',
                {
                  initialValue: _vm.user.twitter,
                  rules: [
                    {
                      required: false,
                      message: 'Please enter your job title',
                    } ],
                } ]),expression:"[\n                'twitter',\n                {\n                  initialValue: user.twitter,\n                  rules: [\n                    {\n                      required: false,\n                      message: 'Please enter your job title',\n                    },\n                  ],\n                },\n              ]"}],staticStyle:{"width":"100%"}})],1)],1)],1),_c('a-row',{attrs:{"gutter":16}},[_c('a-col',{attrs:{"span":24}},[_c('a-form-item',{attrs:{"label":"Registration Certificate"}},[_c('a-upload-dragger',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'reg_certificate',
                {
                  initialValue: _vm.user.reg_certificate,
                  rules: [{ required: true, message: 'Certificate  is required' }],
                } ]),expression:"[\n                'reg_certificate',\n                {\n                  initialValue: user.reg_certificate,\n                  rules: [{ required: true, message: 'Certificate  is required' }],\n                },\n              ]"}],attrs:{"accept":"application/pdf","multiple":false,"list-type":"picture","before-upload":_vm.handleBeforeUpload,"show-upload-list":false,"custom-request":_vm.uploadAdmissionCert}},[_c('div',[_c('p',{staticClass:"ant-upload-drag-icon"},[_c('a-icon',{attrs:{"type":"inbox"}})],1),_c('p',{staticClass:"ant-upload-text"},[_vm._v(" Click or drag file to this area to upload Certificate ")])])])],1),(_vm.uploadProgress>0)?_c('a-progress',{attrs:{"percent":_vm.uploadProgress}}):_vm._e()],1)],1),_c('a-checkbox',{staticClass:"mb-3",attrs:{"checked":_vm.terms},on:{"change":_vm.changeTerms}},[_vm._v(" I understand that the information submitted on this page will be visibile to all potential clients ")])],1),_c('div',[_c('a-button',{attrs:{"type":"primary","loading":_vm.loading,"disabled":_vm.user.status === 'pending approval' || !_vm.terms},on:{"click":_vm.handleSubmit}},[_vm._v("Save and Continue ")])],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }